body {
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  overflow: scroll;
  scroll-behavior: smooth;
  color: black;
  font-weight: 300;
}

* {
  font-size: 16px;
}

@media only screen and (min-width: 1920px) {
  * {
    font-size: 18px;
  }
}

input,
textarea,
select,
.MuiSelect-select {
  -ms-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  font-family: 'Poppins', sans-serif;
  color: black;
}

input::placeholder,
textarea::placeholder,
select::placeholder,
.MuiSelect-select::placeholder {
  color: black;
}

input:disabled,
textarea:disabled,
select:disabled,
.MuiSelect-select:disabled,
input:disabled::placeholder,
textarea:disabled::placeholder,
select:disabled::placeholder,
.MuiSelect-select:disabled::placeholder {
  color: rgba(0, 0, 0, 0.38);
}

div.MuiSelect-select {
  border: 2px solid #d6d6d6;
  outline: none;
  padding: 0.6rem 0.7rem;
}

input:hover,
textarea:hover,
div.MuiSelect-select:hover,
.MuiInputBase-root.MuiAutocomplete-inputRoot:hover {
  border-color: #53b3c0;
}

select:hover {
  outline-color: #53b3c0;
}

input:focus,
textarea:focus,
div.MuiSelect-select:focus {
  border-color: #007d96;
}

select:focus {
  outline-color: #007d96;
}

input:focus-visible,
textarea:focus-visible,
div.MuiSelect-select:focus-visible {
  outline: none;
  border-color: #007d96;
}

select:focus-visible {
  outline-color: #007d96;
}

.carousel .slide img {
  max-height: 75vh;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-next.control-arrow,
.carousel.carousel-slider .control-prev.control-arrow {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  width: 2rem;
}

.carousel .control-arrow:hover,
.carousel.carousel-slider:hover .control-next.control-arrow:hover,
.carousel.carousel-slider:hover .control-prev.control-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.pagination {
  display: flex;
  list-style: none;
  outline: none;
  margin: 0;
  padding: 1rem;
  justify-content: center;
}
.pagination > .active > a {
  border-color: #007d96;
  color: black;
}
.pagination > li > a {
  border: 1px solid #007d96;
  padding: 0.4rem 0.8rem;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #007d96;
  border-color: #007d96;
  color: white;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: black;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

fieldset.MuiOutlinedInput-notchedOutline {
  border: none;
}

.MuiInputBase-root.MuiAutocomplete-inputRoot {
  border: 2px solid #d6d6d6;
  background-color: white;
}

label.MuiInputLabel-root {
  color: black;
}

label.MuiInputLabel-root.Mui-focused {
  color: black;
}
